<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">日游客量</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="景点名称" class="from-item-block">
                    <el-select
                        v-model="formdata.dataDic.LYJDID"
                        placeholder="请选择景点名称"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in siteList"
                            :key="`${index}`"
                            :label="item.MC"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="JCRQ" label="时间" class="from-item-block">
                    <el-date-picker
                        v-model="formdata.dataDic.JCRQ"
                        type="date"
                        placeholder="选择时间"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    prop="YKL"
                    label="日游客量"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.dataDic.YKL"
                        @input="toNumber($event, 'YKL')"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "rykl",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "1103",
                dataDic: {
                    glycbtid: this.heritageId,
                    LYJDID: "",
                    JCRQ: "",
                    YKL: "",
                    JCDXID: null,
                },
            },
            rules: {
                YKL: [
                    {
                        required: true,
                        message: "请填写日游客量",
                        trigger: "blur",
                    },
                ],
            },
            enumData: [],
            ssjdData: [],
            siteList: [], // 景区列表
        };
    },
    mounted() {
        this.GetSiteList();
    },
    methods: {
        ...mapActions(["GetGLXList"]),
        async GetSiteList() {
            let res = await this.GetGLXList({
                itemcode: "1105",
                lm: "jcsj",
            });
            this.siteList = res.ResultValue || [];
        },
    },
};
</script>

<style>
.yearBox .yearItem {
    width: 48% !important;
}
.yearBox .separate {
    padding: 0 10px;
}
</style>
